
@import '../../styles/mixins';
@import '../../styles/variables';

.SocialBlock {
  margin-top: .5rem;
  font-size: 1.25rem;
  text-align: center;
  color: var(--primary-color);
  font-weight: 500;
  transition: $color-transition;

  i {
    color: var(--primary-color);
    transition: $color-transition;
    font-size: 1.25rem;
  }
}

.SocialBlock > a {
  margin-left: .75rem;
}

@media (min-width: 1180px) {
  .SocialBlock {
    text-align: left;
  }
}