@import '../../styles/variables';
@import '../../styles/mixins';

.Cls1, .Cls2, .Cls3, .Cls4 {
  transition: $color-transition;
}

.Cls1 {
  @include project-svg-bg-square;
} 

.Cls5 {
  fill: var(--secondary-color);
}

.Cls2 {
  fill: var(--primary-color);
}

.Cls3 {
  fill:#fff;
}

.Cls4 {
  fill: var(--primary-color-dark);
  stroke: var(--primary-color-dark);
  stroke-linejoin: round;
  stroke-width: 0.25px;
}
