.PostCard {
  flex: 1;
  margin: .5rem .5rem 3rem .5rem;

  h1 {
    font-size: 1.563rem;
    font-weight: 600;
    text-align: left;
  }

  p {
    margin: .75rem 0 0 0;
  }
}