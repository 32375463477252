@import '../../styles/variables';

.Footer {
  background-color: var(--primary-color);
  transition: $color-transition;
  color: #fff;
  padding: 2rem 0;
  text-align: right;

  i {
    color: var(--secondary-color);
    transition: $color-transition;
    font-size: 2rem;
  }
}

.IconBlock {
  max-width: $width-main;
  margin: 0 auto;
  text-align: right;
  padding: 0 2rem;
}

.IconBlock > * {
  margin-left: 1rem;
}