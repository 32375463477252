@import '../../styles/mixins';

.List {
  list-style: disc;
}

.Modal {
  padding-left: 1.25rem;
}

.Skill {
  column-count: 2;
  column-gap: 2rem;
  margin: .5rem auto 1rem auto;
  overflow: hidden;
  padding-left: 1.25rem;
}

@include medium-screens {
  .Skill {
    column-count: 3;
  }
}