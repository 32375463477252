@import '../../styles/variables';
@import '../../styles/mixins';

.Cls1, .Cls2, .Cls3, .Cls4, .Cls5, .Cls6, .Cls7 {
  transition: $color-transition;
}

.Cls1 {
 @include project-svg-bg-square;
} 

.Cls3 {
  fill: var(--secondary-color);
}

.Cls2, .Cls6 { 
  fill: #fff;
}

.Cls2, .Cls3, .Cls4, .Cls6, .Cls7 {
  stroke: #231f20;
  stroke-width: 0.25px;
}

.Cls2, .Cls3 {
  stroke-miterlimit: 10;
}

.Cls4{
  fill: var(--primary-color);
}

.Cls6, .Cls7 {
  stroke-linejoin: round;
}

.Cls7 {
  fill: var(--primary-color-dark);
}
