@import '../../styles/variables';
@import '../../styles/mixins';

.SplitPane {
  max-width: $width-main;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
}

.SplitPaneImg {
  display: none;
}

.SplitPaneText {
  flex: 1;
  padding: 1rem;
  margin: 0 auto;
  max-width: $width-text;

  h2 {
    text-align: left;
  }
}

@media (min-width: 1180px) {
  .SplitPane {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 2rem;

    h1 {
      text-align: left;
    } 
  }

  .SplitPaneImg {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
  }

  .SplitPaneText {
    flex: 2;
    margin: 1rem;
  }
}