@import '../../styles/variables';

.ProjectPage {
  max-width: $width-text;
  margin: 0 auto;
  padding: 4rem 2rem;

  h1 {
    color: var(--primary-color)
  }

  p {
    margin: 2rem 0;
  }

  h2 {
    margin-top: .25rem;
  }

  h3 {
    font-size: 1.563rem;
    text-align: center;
    margin: 2rem 0 1rem 0;
  }

  ul {
    margin-bottom: 1rem;
  }
}

.Img {
  width: 100%;
  margin: 0 auto;
  border: 1px solid $gray-primary;
}