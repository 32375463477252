@import '../../styles/mixins';
@import '../../styles/variables';

.CardWrapper {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  max-width: $width-main;
  margin: 1rem auto;
}

@include medium-screens {
    .CardWrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }

@include large-screens {
  .CardWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}