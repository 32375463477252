.LinkBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.LinkBlock > * {
  margin-right: 1rem;
}

@media (max-width: 415px) {
  .LinkBlock {
    justify-content: left;
    align-items: left;
  }
}