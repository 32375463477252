@import '../../styles/variables';

.ModalContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 999;
}
  
.ModalContent {
  background-color: #fff;
  border-radius: 3px;
  max-width: $width-text; 
  margin: 50px 1rem;

  @media (min-width: $width-text) {
    max-width: calc(640px - 2rem); //640 from $width-text
    margin: 50px auto;
    padding: 0 2rem;
  }
}

.ModalOpen {
  overflow: hidden;
}

.ModalHeader {
  position: relative;
}

.CloseBtn {
  padding: .75rem;
  margin: 0;
  background: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  font-weight: bold;
  font-size: 1.25rem;
  color: gray;
  cursor: pointer;

  @media (min-width: $width-text) {
    margin-right: -2rem;
  }

  &:hover {
    color: var(--primary-color);
  }
}