.Purple {
  --primary-color: #6525CC;
  --primary-color-dark: #391573;
  --secondary-color: #B186F9;
}

.Orange {
  --primary-color: #FF7033;
  --primary-color-dark: #C25424;
  --secondary-color: #FFAD33;
}

.Teal {
  --primary-color: #1898A0;
  --primary-color-dark: #0F6B71;
  --secondary-color: #30E2CD;
}