@import '../../styles/variables';
@import '../../styles/mixins';


.Cls1, .Cls2, .Cls3, .Cls4, .Cls5, .Cls6, .Cls7, .Cls8 {
  transition: $color-transition;
}

.Cls1 {
  @include project-svg-bg-square;
} 

.Cls2 {
  fill: var(--secondary-color);
}

.Cls3 {
  fill: var(--primary-color);
}

.Cls4 {
  fill: #fff;
}

.Cls5, .Cls6 {
  fill: none;
  stroke: var(--secondary-color);
  stroke-miterlimit: 10;
}

.Cls5 {
  stroke-width: 0.75px;
}

.Cls7 {
  fill: var(--primary-color-dark);
}

.Cls8 {
  opacity: 0.1;
  isolation:isolate;
}
