@import '../../styles/mixins';
@import '../../styles/variables';

.Cls1, .Cls2, .Cls3, .Cls4, .Cls5, .Cls6 {
  transition: $color-transition;
}

.Cls1, .Cls4 {
  fill:#fff;
}

.Cls5 {
  fill: var(--secondary-color);
}

.Cls1, .Cls3, .Cls4, .Cls6 {
  stroke: var(--secondary-color);
  stroke-miterlimit:10;
}

.Cls1 {
  stroke-width:2px;
}

.Cls2 {
  fill: var(--primary-color);
}

.Cls3, .Cls6 {
  fill:none;
}

.Cls3, .Cls4 {
  stroke-width:4px;
}

.Cls6 {
  stroke-width:6px;
}

.Cls5 {
  stroke-width: 0
}