@import '../../styles/mixins';
@import '../../styles/color-themes';

.NavLinks {
  display: none;
  padding-top: 1rem;
}

.NavLink {
  text-align: center;
  margin: 1rem auto;
}

.Open {
  display: block;
}

@include large-screens {
  .NavLinks {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: initial;
  }

  .NavLink {
    margin: 0 0 0 2rem;
  }
}