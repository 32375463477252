@import './styles/variables';

.Spacer {
  height: 70px;
}

#posts > h1, #projects > h1 {
  padding-top: 2rem;
}

.Center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Skills {
  margin-top: 1rem;
}