@import '../../styles/variables';
@import '../../styles/mixins';

.Button {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  padding: .5rem 1.5rem;
  font-weight: 600;
  font-size: initial;
  transition: $color-transition;
  margin-top: 1rem;
}

a.Button {
  display: inline-block;

  &:hover {
    text-decoration: initial;
  }
}

.Primary {
  color: #fff;
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color-dark);
  }
}

.PrimaryInverse {
  background-color: #fff;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
    border: 2px solid #fff;
  }
}

.Secondary {
  border: 2px solid var(--primary-color);
  background-color: initial;

  &:hover {
    color: #fff;
    background-color: var(--primary-color);
    border: 2px solid rgba(255, 255, 255, 0);
  }
}

.SecondaryInverse {
  border: 2px solid #fff;
  color: #fff;
  background-color: initial;

  &:hover {
    color: var(--primary-color);
    background-color: #fff;
    border: 2px solid rgba(255, 255, 255, 0);
  }
}

.Wide {
  padding-left: 5rem;
  padding-right: 5rem;
}

.Center {
  display: flex;
  align-items: center;
  justify-content: center;

  .Button {
    margin: 0;
    color: green;
  }
}
