@import '../../styles/variables';
@import '../../styles/mixins';

.Hero {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 2rem 4rem 2rem;
  overflow-x: hidden;

  &>* {
    margin: .5rem;
  }

  h1 {
    font-size: 2.44rem;
    padding-top: .5rem;
  }

  h2 {
    padding-top: 1rem;
    font-size: 1.25rem;
    font-weight: initial;
    line-height: 1.5;
  }
}

.Left {
  flex: 1;
}

.Right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

// Medium screens styles
@media (min-width: 520px) {
  .Hero {
    padding: 4rem;
    
    &>* {
      margin: 1rem;
    }

    h1 {
      font-size: 3.052rem;
    }

    h2 {
      padding-top: .5rem;
      max-width: 500px;
      margin: 0 auto;
    }
  }

  svg {
    max-width: 500px;
  }
}

@include large-screens {
  .Hero {
    flex-direction: row;
    max-width: $width-main;
    margin: 0 auto;
    padding: 6rem;

    &>* {
      margin: 0;
    }

    h1 {
      line-height: 1.1;
      text-align: left;
      padding-bottom: 1rem;
      font-size: 3.815rem;
    }
  
    h2 {
      text-align: left;  
      max-width: $width-text; 
    }
  } 

  .Left {
    flex: .75;
  }

  svg {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .Hero {
    h1 {
      font-size: 4.5rem;
    }

    h2 {
      font-size: 1.5rem;  
    }
  }  
}